const toggleClass = 'sidebar-on';
const navigation = document.querySelector('nav .wrapper .menu-wrapper');
let isMenuOpen = false;

const toggleMenu = () => {
    navigation.classList.toggle(toggleClass);
};

// Toggle open state when clicking the button
document
    .querySelector('.menu-toggle button')
    .addEventListener('click', toggleMenu);

// Toggle open state after transition
navigation.addEventListener('transitionend', function() {
    isMenuOpen = navigation.classList.contains(toggleClass);
});

// Close Menu if clicked outside
document.addEventListener('click', function($event) {
    if (isMenuOpen && !$event.target.closest('.menu-wrapper')) {
        navigation.classList.remove(toggleClass);
    }
});

// Polyfill for Element.closest() >>> IE 9+
if (!Element.prototype.matches)
    Element.prototype.matches =
        Element.prototype.msMatchesSelector ||
        Element.prototype.webkitMatchesSelector;

if (!Element.prototype.closest)
    Element.prototype.closest = function(s) {
        var el = this;
        if (!document.documentElement.contains(el)) return null;
        do {
            if (el.matches(s)) return el;
            el = el.parentElement || el.parentNode;
        } while (el !== null && el.nodeType === 1);
        return null;
    };

module.exports = {
    toggleMenu
};
