const navScrolledClass = 'nav-scrolled';

let nav = document.querySelector('nav');
let header = document.querySelector('header');
let button = document.querySelector('.menu-toggle button');
let headerImage = document.querySelector('.container-header img');
let isBigHeader = !!document.querySelector('body.big-header');

const onScroll = () => {
    if (window.scrollY > 10) {
        nav.classList.add(navScrolledClass);
    } else {
        nav.classList.remove(navScrolledClass);
    }
    let basePositionY = isBigHeader ? 25 : 50;
    // After having scrolled, 1/3 of the entire screen, the paralax will be finished
    let positionY =
        basePositionY - ((window.scrollY / window.innerHeight) * 100) / 3;
    headerImage.style.transform = `translate(-0%, -${positionY}%`;
};

window.addEventListener('scroll', onScroll);
