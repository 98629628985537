import { toggleMenu } from './sidebar';

const navigation = document.querySelector('nav .wrapper .menu-wrapper');
let draggingOriginX = 0;
let draggingDistance = 0;

// Drag menu off
const onDrag = $event => {
    const current = $event.targetTouches
        ? $event.targetTouches.item(0).screenX
        : $event.screenX;
    draggingDistance = current - draggingOriginX;
    // Do not allow dragging to the left
    if (draggingDistance < 0) {
        return;
    }

    navigation.style.transform = `translateX(${draggingDistance}px)`;
};

// Reset variables and close sidebar
const resetDrag = () => {
    // Close sidebar ?
    if (draggingDistance / navigation.clientWidth > 0.5) {
        toggleMenu();
    }
    // Reset
    navigation.style.transform = '';
    draggingDistance = 0;
};

// Bind listeners for move event
const startDrag = function($event) {
    draggingOriginX = $event.targetTouches
        ? $event.targetTouches.item(0).screenX
        : $event.screenX;
    navigation.addEventListener('mousemove', onDrag);
    navigation.addEventListener('touchmove', onDrag);
};

// Unbind listeners for move event
const stopDrag = function() {
    navigation.removeEventListener('mousemove', onDrag);
    navigation.removeEventListener('touchmove', onDrag);
    resetDrag();
};

// Start drag and listen
navigation.addEventListener('mousedown', startDrag);
navigation.addEventListener('touchstart', startDrag);

// Reset drag and stop listening
navigation.addEventListener('mouseup', stopDrag);
navigation.addEventListener('mouseleave', stopDrag);
navigation.addEventListener('touchend', stopDrag);
